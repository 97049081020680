import React from "react";
import { useState, useEffect } from "react";
import NavBar from "../components/topNav";
import ListItem from "../components/listItem";
import { getApplicants } from "../axios";

import gray_dot from "../images/gray_dot.png";
import sort_icon from "../images/sort_icon.png";

// TODO: Page number

export default function ApplicantList() {
  // const { state: {applicants: applicants, grades: grades} } = useLocation()
  // const applicants = getApplicants(localStorage.getItem("token"))
  const [applicants, setApplicants] = useState([]);
  const [status, setStatus] = useState("idle");
  const [keyword, setKeyword] = useState(localStorage.getItem("keyword") ?? "");
  const [phase, setPhase] = useState(
    Number(localStorage.getItem("phase")) ?? 0
  );
  const [graded, setGraded] = useState(
    Number(localStorage.getItem("graded")) ?? 0
  );
  const [search, setSearch] = useState(
    Number(localStorage.getItem("search")) ?? 0
  );

  const [time, setTime] = useState(Number(localStorage.getItem("time")) ?? 0);

  if (status === "fetched") {
    console.log(applicants);
  }

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    setStatus("fetching");
    const app = await getApplicants(localStorage.getItem("token"));
    setApplicants(app);
    setStatus("fetched");
  };

  const attrs1 = [
    "GIS Code",
    "Name",
    "Nationality",
    "Education Level",
    "Status",
    "AP Priority 1",
    "AP Priority 2",
    "AP Priority 3",
    "AP Priority 4",
    "Grade 1",
    "Grade 2",
    "",
  ];

  const attrs2 = [
    "GIS Code",
    "Name",
    "Nationality",
    "Education Level",
    "Status",
    "AP Priority 1",
    "AP Priority 2",
    "AP Priority 3",
    "AP Priority 4",
    "Grade",
    "",
  ];

  const search_index = [
    "gis_code",
    "name",
    "nationality",
    "education_level",
    "first_choice",
    "second_choice",
    "third_choice",
    "fourth_choice",
    "essay_grade",
  ];

  return (
    <div className="w-full table">
      <NavBar
        setKeyword={setKeyword}
        keyword={keyword}
        phase={phase}
        setPhase={setPhase}
        graded={graded}
        setGraded={setGraded}
        search={search}
        setSearch={setSearch}
        time={time}
        setTime={setTime}
      />

      <table className="pl-4 mt-[50px] h-[55px] w-full text-gray-700 text-sm items-center flex-wrap table">
        <thead className="table">
          <tr className="pl-4 flex mx-[60px] h-[55px] text-sm items-center border-b text-left">
            {phase === 0
              ? attrs1.map((value, index) => (
                  <th
                    key={index}
                    title={value}
                    className="w-[100px] whitespace-nowrap bg-white h-[20px] mr-12 z-0"
                  >
                    {value}
                  </th>
                ))
              : attrs2.map((value, index) => (
                  <th
                    key={index}
                    title={value}
                    className="w-[100px] whitespace-nowrap bg-white h-[20px] mr-12 z-0"
                  >
                    {value}
                  </th>
                ))}
          </tr>
        </thead>
        <tbody className="table">
          {applicants
            .filter((applicant) => {
              if (phase === 1) {
                return (
                  applicant.essay_grade_1 >= 60 || applicant.essay_grade_2 >= 60
                );
              }
              return true;
            })
            .filter((applicant) => {
              switch (time) {
                case 0:
                  return applicant.create_time < "2022-10-10T00:00:00.000000Z";
                case 1:
                  return applicant.create_time >= "2022-10-10T00:00:00.000000Z" && applicant.create_time < "2022-12-01T00:00:00.000000Z";
                case 2:
                  return applicant.create_time >= "2022-12-01T00:00:00.000000Z";
              }
            })
            .filter((applicant) => {
              if (graded === 0) {
                return true;
              } else if (phase === 0) {
                const retVal =
                  applicant.essay_grade_1 !== null ||
                  applicant.essay_grade_2 !== null;
                return graded === 1 ? retVal : !retVal;
              } else if (phase === 1) {
                const retVal =
                  applicant.overall !== null &&
                  applicant.academic !== null &&
                  applicant.behavior !== null &&
                  applicant.challenging !== null &&
                  applicant.self_introduction !== null;
                return graded === 1 ? retVal : !retVal;
              }
            })
            .filter((applicant) => {
              if (keyword !== "") {
                if (
                  String(applicant[search_index[search]])
                    .toLocaleLowerCase()
                    .indexOf(keyword.toLocaleLowerCase()) < 0
                )
                  return false;
              }
              return true;
            })
            .map((value, index) => (
              <ListItem key={index} applicant={value} phase={phase} />
            ))}
        </tbody>
      </table>
      <br />
    </div>
  );
}
