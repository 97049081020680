import React, { useState, useEffect, useRef } from "react";

import icon from "../images/down_icon.png";

// TODO: Close when clicking other places, or when mouse leaves (probably not gonna implement)

export default function Dropdown({ options, selected, setSelected, itemName }) {
  const [expand, setExpand] = useState(false);

  const ref = useRef(null);
  const clickOut = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setExpand(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", clickOut);

    return () => {
      window.removeEventListener("mousedown", clickOut);
    };
  }, []);

  return expand ? (
    <div
      ref={ref}
      className={`w-[180px] ml-6 z-50`}
      style={{ marginTop: (options.length - 1) * 36 + "px" }}
    >
      {options.map((value, index, options) => (
        <Item
          key={index}
          name={value}
          rounded={
            index === 0
              ? "rounded-t-xl "
              : index + 1 === options.length
              ? "rounded-b-xl "
              : ""
          }
          border={
            index === 0
              ? "border-gray-400 border "
              : "border-gray-400 border-b border-x"
          }
          hover={"hover:bg-gray-200"}
          onClick={() => {
            setSelected(index);
            localStorage.setItem(itemName, index);
            setExpand(false);
          }}
        />
      ))}
    </div>
  ) : (
    <div
      className={
        "flex items-center justify-center rounded-xl ml-6 w-[180px] h-[36px] bg-white border border-gray-400"
      }
    >
      <Item
        name={options[selected]}
        rounded="rounded-xl "
        border={"bg-opacity-0"}
      />
      <img
        className="h-7 ml-auto mr-3"
        src={icon}
        onClick={() => setExpand(true)}
      />
    </div>
  );
}

function Item({ name, rounded, border, hover, onClick }) {
  return (
    <div
      className={`bg-white flex p-2 h-[36px] w-[180px] items-center z-50 whitespace-nowrap ${hover} ${rounded} ${border}`}
      onClick={onClick}
    >
      {name}
    </div>
  );
}
