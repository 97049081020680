import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { changeEssay } from "../axios";

import arrow from "../images/back_arrow.png";

export default function Phase1({ applicant }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  let navigate = useNavigate();
  const [score1, score1Input] = useInput({
    initValue: applicant.essay_grade_1,
    className:
      "w-full py-2 mt-4 text-black text-base leading-tight focus:outline-none focus:shadow- focus:border-orange-950 border-b-2 mb-4",
  });
  const [score2, score2Input] = useInput({
    initValue: applicant.essay_grade_2,
    className:
      "w-full py-2 mt-4 text-black text-base leading-tight focus:outline-none focus:shadow- focus:border-orange-950 border-b-2 mb-4",
  });

  function useInput({ initValue, className }) {
    const [value, setValue] = useState(initValue);
    const input = (
      <input
        className={className}
        value={value ?? ""}
        onChange={(e) => setValue(e.target.value)}
        type="number"
        min={0}
        // disabled={value ? true : false}
      />
    );
    return [value, input];
  }

  const back = async () => {
    const jwt = localStorage.getItem("token");
    navigate("/list");
  };

  const onComplete = async () => {
    const token = localStorage.getItem("token");
    try {
      await changeEssay(token, applicant, score1, score2);
      await back();
    } catch (e) {
      alert(e);
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="flex min-w-full h-[55px] border border-b-gray-200 items-center bg-white">
        <div onClick={async () => await back()}>
          <button className="bg-[#F5F6F9] ml-[10px] rounded-xl flex items-center hover:border px-2 h-[30px] text-sm text-gray-700">
            <img className="w-5 h-5 mr-1" src={arrow} />
            Back
          </button>
        </div>
      </div>

      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
        <div className="h-screen w-2/3">
          <Viewer
            fileUrl={applicant.essay_assignment}
            plugins={[defaultLayoutPluginInstance]}
          />
        </div>
      </Worker>

      <div className="flex flex-wrap w-[calc(33%-80px)] h-[700px] mt-6 pl-8 text-sm items-center text-gray-400">
        <div className="flex w-[calc(33%-80px)] h-[80px] border-b border-b-gray-200 items-center mb-8">
          <p className="flex items-center ml-6 h-[80px] text-black border-b border-b-black cursor-default">
            Grading
          </p>
        </div>

        <p className="flex w-full text-black mb-6 text-base">
          {applicant.name}
        </p>
        <div className="w-full">
          <p className="flex w-full">Essay Grade 1</p>
          {score1Input}
        </div>
        <div className="w-full">
          <p className="flex w-full">Essay Grade 2</p>
          {score2Input}
        </div>
        <div className="w-full flex items-end justify-end">
          <button
            className="mt-8 mr-8 w-[250px] h-[40px] rounded-full bg-blue-950 text-white"
            onClick={async () => onComplete()}
          >
            Complete
          </button>
        </div>
      </div>
    </div>
  );
}
