import React from "react";
import { useEffect } from "react";

import logo from "../images/logo.png";
import phase1 from "../images/phase1_icon.png";
import phase2 from "../images/phase2_icon.png";

export default function SideNav({ setPhase, phase }) {
  return (
    <div className="flex flex-col items-center w-[80px] h-full fixed left-0 top-0 bg-blue-950">
      <div className="w-[80px] h-[80px] flex items-center justify-center">
        <img className="w-12 h-12" src={logo} />
      </div>

      <hr className="bg-gray-300 h-[1px] w-[40px] shadow" />

      <div className="w-[80px] h-[80px] flex items-center justify-center">
        <img
          className={`w-11 h-11 rounded-xl ${
            phase === 0 ? "brightness-75" : "shadow-3xl cursor-pointer"
          }`}
          src={phase1}
          onClick={() => setPhase(0)}
        />
      </div>

      <hr className="bg-gray-300 h-[1px] w-[40px] shadow" />

      <div className="w-[80px] h-[80px] flex items-center justify-center">
        <img
          className={`w-11 h-11 rounded-xl ${
            phase === 1 ? "brightness-75" : "shadow-3xl cursor-pointer"
          }`}
          src={phase2}
          onClick={() => setPhase(1)}
        />
      </div>
    </div>
  );
}
