import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { changeGrade } from "../axios";

import arrow from "../images/back_arrow.png";

export default function Phase2({ applicant, grade }) {
  const [panel, setPanel] = useState(1);
  const inputClass =
    "appearance-none w-full py-2 text-black text-base leading-tight focus:outline-none focus:shadow- focus:border-orange-950 border-b-2 mb-4";

  const [intro, introInput] = useInput({
    initValue: applicant.self_introduction,
    className: inputClass,
  });

  const [behavior, behaviorInput] = useInput({
    initValue: applicant.behavior,
    className: inputClass,
  });

  const [academic, academicInput] = useInput({
    initValue: applicant.academic,
    className: inputClass,
  });

  const [challenging, challengingInput] = useInput({
    initValue: applicant.challenging,
    className: inputClass,
  });

  const [overall, overallInput] = useInput({
    initValue: applicant.overall,
    className: inputClass,
  });
  const total =
    Number(overall) +
    Number(challenging) +
    Number(academic) +
    Number(behavior) +
    Number(intro);

  let navigate = useNavigate();

  const back = async () => {
    const jwt = localStorage.getItem("token");
    navigate("/list");
  };

  const onComplete = async (g) => {
    const token = localStorage.getItem("token");
    try {
      await changeGrade(token, applicant, g);
      await back();
    } catch (e) {
      alert(e);
    }
  };

  function useInput({ initValue, className }) {
    const [value, setValue] = useState(initValue);
    const input = (
      <input
        className={className}
        value={value ?? ""}
        onChange={(e) => setValue(e.target.value)}
        type="number"
        min={0}
        // disabled={value ? true : false}
      />
    );
    return [value, input];
  }

  return (
    <div className="flex flex-row w-full flex-wrap">
      <div className="flex min-w-full h-[55px] border border-b-gray-200 items-center bg-white">
        <div onClick={async () => await back()}>
          <button className="bg-[#F5F6F9] ml-[90px] rounded-xl flex items-center hover:border px-2 h-[30px] text-sm text-gray-700">
            <img className="w-5 h-5 mr-1" src={arrow} />
            Back
          </button>
        </div>
      </div>

      <div className="flex w-1/2 h-[80px] border-b border-b-gray-200 items-center ml-[80px] text-gray-400">
        <p
          className={`flex items-center ml-6 h-[80px] ${
            panel == 1 ? "text-black border-b border-b-black" : "cursor-pointer"
          }`}
          onClick={() => setPanel(1)}
        >
          Personal Information
        </p>
        <p
          className={`flex items-center ml-6 h-[80px] ${
            panel == 2 ? "text-black border-b border-b-black" : "cursor-pointer"
          }`}
          onClick={() => setPanel(2)}
        >
          Priority
        </p>
      </div>

      <div className="flex w-[calc(50%-80px)] h-[80px] border-b border-b-gray-200 items-center">
        <p className="flex items-center ml-6 h-[80px] text-black border-b border-b-black cursor-default">
          Grading
        </p>
      </div>

      {panel == 1 ? (
        <div className="flex flex-wrap w-1/3 mt-6 pl-8 ml-[80px] text-sm items-center text-gray-400">
          <p className="flex w-1/2">GIS Code</p>
          <p className="flex w-1/2">Name</p>

          <p className="flex w-1/2 text-base pb-2 border-b-2 whitespace-nowrap">
            {applicant.gis_code ?? "Null"}
          </p>
          <p className="flex w-1/2 text-base pb-2 border-b-2 whitespace-nowrap">{`${
            applicant.first_name ?? "Null"
          } ${applicant.last_name ?? ""}`}</p>
          <p className="flex w-1/2">Preferred Name</p>
          <p className="flex w-1/2">Sex</p>
          <p className="flex w-1/2 text-base pb-2 border-b-2 whitespace-nowrap">
            {applicant.prefer_name ?? "Null"}
          </p>
          <p className="flex w-1/2 text-base pb-2 border-b-2 whitespace-nowrap">
            {applicant.sex ?? "Null"}
          </p>

          <p className="flex w-1/2">Nationality</p>
          <p className="flex w-1/2">Preferred Gender Pronoun</p>
          <p className="flex w-1/2 text-base pb-2 border-b-2 whitespace-nowrap">
            {applicant.nationality ?? "Null"}
          </p>
          <p className="flex w-1/2 text-base pb-2 border-b-2 whitespace-nowrap">
            {applicant.prefer_gender ?? "Null"}
          </p>

          <p className="flex w-1/2">Current Institution</p>
          <p className="flex w-1/2">Major</p>
          <p className="flex w-1/2 text-base pb-2 border-b-2 whitespace-nowrap">
            {applicant.education_institution ?? "Null"}
          </p>
          <p className="flex w-1/2 text-base pb-2 border-b-2 whitespace-nowrap">
            {applicant.major ?? "Null"}
          </p>
        </div>
      ) : (
        <div className="flex flex-wrap w-1/3 mt-6 pl-8 ml-[80px] text-sm items-center text-gray-400">
          <p className="flex w-full">AP Priority 1</p>
          {/* <p className="flex w-1/2">Speech Priority 1</p> */}
          <p className="flex w-full text-base pb-2 border-b-2">
            {applicant.first_choice}
          </p>
          {/* <p className="flex w-1/2 text-base pb-2 border-b-2">{applicant.priority.speech_priority_1}</p> */}

          <p className="flex w-full">AP Priority 2</p>
          {/* <p className="flex w-1/2">Speech Priority 2</p> */}
          <p className="flex w-full text-base pb-2 border-b-2">
            {applicant.second_choice}
          </p>
          {/* <p className="flex w-1/2 text-base pb-2 border-b-2">{applicant.priority.speech_priority_2}</p> */}

          <p className="flex w-full">AP Priority 3</p>
          <p className="flex w-full text-base pb-2 border-b-2">
            {applicant.third_choice}
          </p>

          <p className="flex w-full">AP Priority 4</p>
          <p className="flex w-full text-base pb-2 border-b-2">
            {applicant.fourth_choice}
          </p>
        </div>
      )}

      <div className="min-w-1/6"></div>

      <div className="flex flex-wrap w-[calc(50%-100px)] mt-6 pl-8 text-sm items-center">
        <p className="flex w-full text-gray-400">Essay Grade 1</p>
        <p className="appearance-none w-full py-2 text-base leading-tight focus:outline-none focus:shadow- focus:border-orange-950 border-b-2 mb-4 text-gray-400">
          {applicant.essay_grade_1}
        </p>
        <p className="flex w-full text-gray-400">Essay Grade 2</p>
        <p className="appearance-none w-full py-2 text-base leading-tight focus:outline-none focus:shadow- focus:border-orange-950 border-b-2 mb-4 text-gray-400">
          {applicant.essay_grade_2}
        </p>

        <p className="flex w-full">Self Introduction</p>
        {introInput}

        <p className="flex w-full">Behavior</p>
        {behaviorInput}

        <p className="flex w-full">Academic</p>
        {academicInput}

        <p className="flex w-full">Challenging</p>
        {challengingInput}
      </div>

      <div className="min-w-[calc(50%+80px)]"></div>

      <div className="flex flex-wrap w-[calc(50%-100px)] pl-8 text-sm items-center ">
        <p className="flex w-full">Overall</p>
        {overallInput}

        <p className="flex w-full text-gray-400">Total</p>
        <p className="appearance-none w-full py-2 text-base leading-tight focus:outline-none focus:shadow- focus:border-orange-950 border-b-2 mb-4 text-gray-400">
          {total}
        </p>
      </div>
      <div className="w-full flex items-end justify-end">
        <button
          className="mt-8 mr-8 w-[250px] h-[40px] rounded-full bg-blue-950 text-white"
          onClick={() =>
            onComplete({
              self_introduction: intro,
              behavior: behavior,
              academic: academic,
              challenging: challenging,
              overall: overall,
            })
          }
        >
          Complete
        </button>
      </div>
    </div>
  );
}
