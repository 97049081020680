import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import NavBar from "../components/sideNav";
import Phase1 from "../components/phase1";
import Phase2 from "../components/phase2";

export default function GradingPage({}) {
  // let { id } = useParams();
  const {
    state: { applicant: applicant, phase: initPhase },
  } = useLocation();

  const [phase, setPhase] = useState(initPhase);

  return (
    <>
      <NavBar setPhase={setPhase} phase={phase} />
      <div className=" fixed left-[80px] w-[calc(100%-80px)] flex flex-col">
        {phase == 0 ? (
          <Phase1 applicant={applicant} />
        ) : (
          <Phase2 applicant={applicant} />
        )}
      </div>
    </>
  );
}
