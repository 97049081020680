import React from 'react'
import { useState } from 'react'
import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom'

import Login from './pages/login'
import ApplicantList from './pages/applicants'
import GradingPage from './pages/grading'

export default function App() {

    // DONE TODO Store token in localstorage so that I don't have to pass it to literally every component

    const [data, setData] = useState([])

  return (
    <div className="h-screen">
        <BrowserRouter>
            <div className="w-full">
                <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<Login setData={setData} />} />
                    <Route path="/list" element={<ApplicantList data={data} setData={setData} />} />
                    <Route path="/grading/:id" element={<GradingPage />} />
                </Routes>
            </div>
        </BrowserRouter>
    </div>
  )
}
