import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "../components/dropdown";

import logo from "../images/logo.png";
import icon from "../images/search_icon.png";

import gray_dot from "../images/gray_dot.png";
import green_dot from "../images/green_dot.png";
import yellow_dot from "../images/yellow_dot.png";
import logout from "../images/logout_icon.png";

// TODO: Add search field dropdown menu beside search bar

export default function TopNav({
  setKeyword,
  keyword,
  phase,
  setPhase,
  graded,
  setGraded,
  search,
  setSearch,
  time,
  setTime,
}) {
  const search_options = [
    <p className="ml-3">GIS Code</p>,
    <p className="ml-3">Name</p>,
    <p className="ml-3">Nationality</p>,
    <p className="ml-3">Education Level</p>,
    <p className="ml-3">AP Priority 1</p>,
    <p className="ml-3">AP Priority 2</p>,
    <p className="ml-3">AP Priority 3</p>,
    <p className="ml-3">AP Priority 4</p>,
  ];

  const grade_options = [
    <>
      <img className="w-6 h-6 mr-2" src={gray_dot} />
      <p>All</p>
    </>,
    <>
      <img className="w-6 h-6 mr-2" src={green_dot} />
      <p>Graded</p>
    </>,
    <>
      <img className="w-6 h-6 mr-2" src={yellow_dot} />
      <p>Ungraded</p>
    </>,
  ];

  const phase_options = [
    <p className="ml-3">Phase 1</p>,
    <p className="ml-3">Phase 2</p>,
  ];

  const time_options = [
    <p className="ml-3">Early Bird</p>,
    <p className="ml-3">Regular</p>,
    <p className="ml-3">Late</p>,
  ];

  return (
    <div className="flex bg-blue-950 w-full h-[85px] items-center">
      <img className="w-16 h-16 m-2" src={logo} />
      <p className="text-white m-10 text-lg align-top">Applicants</p>
      <Dropdown
        options={search_options}
        setSelected={setSearch}
        selected={search}
        itemName={"search"}
      />

      <form className="flex items-center ml-6">
        <button className="bg-white rounded-l-full" type="submit">
          <img
            className="w-10 h-10 m-0 rounded-l-full"
            src={icon}
            alt="button"
          />
        </button>
        <input
          className="h-10 w-[330px] pl-1 align-middle rounded-r-full focus:outline-none"
          type="text"
          placeholder="Search"
          onChange={(e) => {
            setKeyword(e.target.value);
            localStorage.setItem("keyword", e.target.value);
          }}
          value={keyword}
        />
      </form>

      <Dropdown
        options={grade_options}
        setSelected={setGraded}
        selected={graded}
        itemName={"graded"}
      />

      <Dropdown
        options={phase_options}
        setSelected={setPhase}
        selected={phase}
        itemName={"phase"}
      />

      <Dropdown
        options={time_options}
        setSelected={setTime}
        selected={time}
        itemName={"time"}
      />

      <div className="ml-auto mr-6">
        <Link to={"/login"}>
          <img className="w-9 h-9 rounded-xl" src={logout} />
        </Link>
      </div>
    </div>
  );
}
