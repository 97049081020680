import React from "react";
import { useState } from "react";
import BackgroundSlider from "react-background-slider";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import instance from "../axios";
import { login } from "../axios";

import background1 from "../images/background1.jpg";
import background2 from "../images/background2.jpg";
import background3 from "../images/background3.jpg";
import background4 from "../images/background4.jpg";
import background5 from "../images/background5.jpg";
import logo from "../images/logo_blue.png";
import logo_word from "../images/logo_words.png";

// FIXME RWD is awful

export default function Login({ setData }) {
  const [account, setAccount] = useState("");
  const [passwd, setPasswd] = useState("");
  let navigate = useNavigate();

  const onFormSubmit = async (event) => {
    event.preventDefault();
    if (account === "" || passwd === "") {
      alert("Empty username or password");
      return;
    }
    try {
      const res = await login(account, passwd);
      localStorage.setItem("token", res);
      navigate("/list");
    } catch (err) {
      alert(`Something went wrong: ${err}`);
    }
  };

  return (
    <div className="flex h-screen w-full justify-center items-center">
      <div className="absolute overflow-auto bg-black/60 w-full h-full">
        <BackgroundSlider
          images={[
            background1,
            background2,
            background3,
            background4,
            background5,
          ]}
          duration={8}
          transition={2}
        />
      </div>
      <img
        className="absolute w-[200px] h-[45px] top-[30px] left-[90px]"
        src={logo_word}
      />

      <form
        className="relative flex flex-col bg-white px-6 h-auto w-1/3 min-w-max rounded-2xl z-0"
        onSubmit={onFormSubmit}
      >
        <div className="mt-20 text-2xl font-bold font-mono text-blue-950">
          <img
            className="h-12 w-12 inline-block mr-4 align-middle"
            src={logo}
          />
          GIS Admin
        </div>
        <div className="mt-12 text-2xl font-bold font-mono">Login</div>
        <div className="mt-8 text-xs relative">
          <input
            autoComplete="off"
            className="appearance-none w-full py-2 leading-tight focus:outline-none focus:shadow- focus:border-blue-950 border-b-2 transition-colors duration-300 peer"
            id="username"
            type="text"
            value={account}
            onChange={(e) => setAccount(e.target.value)}
          />
          <label
            htmlFor="username"
            className={
              account.length == 0
                ? "peer-focus:-translate-y-6 peer-focus:text-blue-950 absolute left-0 py-2 cursor-text duration-300 transition-all text-gray-400"
                : "-translate-y-6 text-blue-950 absolute left-0 py-2 cursor-text duration-300 transition-all"
            }
          >
            Username
          </label>
        </div>
        <div className="mt-6 text-xs relative">
          <input
            autoComplete="off"
            className="appearance-none w-full py-2 leading-tight focus:outline-none focus:shadow- focus:border-blue-950 border-b-2 transition-colors duration-300 peer"
            id="password"
            type="password"
            onChange={(e) => setPasswd(e.target.value)}
            value={passwd}
          />
          <label
            htmlFor="password"
            className={
              passwd.length == 0
                ? "peer-focus:-translate-y-6 peer-focus:text-blue-950 absolute left-0 py-2 cursor-text duration-300 transition-all text-gray-400"
                : "-translate-y-6 text-blue-950 absolute left-0 py-2 cursor-text duration-300 transition-all"
            }
          >
            Password
          </label>
        </div>
        {/* <div className="mt-6">
          <input
            className="h-4 w-4 border border-gray-300 rounded bg-white align-middle checked:bg-blue-950 checked:border-blue-950 focus:outline-none cursor-pointer"
            type="checkbox"
            id="RememberMe"
            name="RememberMe"
          />
          <label className="inline-block ml-1 text-xs" htmlFor="RememberMe">
            Remember Me
          </label>
        </div> */}

        <div className="flex flex-row mt-10 mb-20 items-center w-full h-[55px]">
          <button
            className="p-2 font-light w-full h-full bg-blue-950 text-white rounded-lg"
            type="submit"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
}
