import axios from "axios";

const instance = axios.create({
  baseURL: "https://be.admin.gis-taiwan.ntu.edu.tw",
  // baseURL: 'http://127.0.0.1:8000',
});

// TODO Session storage so that I don't have to re login everytime I update something
// TODO Or the 'remember me' function

const login = async (username, password) => {
  const {
    data: { access: token, refresh: refresh },
  } = await instance.post("/auth/jwt/create", {
    username: username,
    password: password,
  });
  return token;
};

const getApplicants = async (token) => {
  const {
    data: { results: results },
  } = await instance.get("/delegate?ordering=create_time&limit=200", {
    headers: { Authorization: `Bearer ${token}` },
  });
  results.map((applicant) => {
    applicant.name = `${applicant.first_name} ${applicant.last_name}`;
  });

  return results;
};

const changeGrade = async (token, applicant, grade) => {
  for (const field in grade) {
    const score = grade[field];
    if (score < 0) {
      throw "Scores cannot be negative";
    }
    if (score === null) {
      throw "All scores must be filled";
    }
  }
  const res = await instance.patch(
    `/delegate/${applicant.id}/`,
    {
      self_introduction: grade.self_introduction,
      behavior: grade.behavior,
      academic: grade.academic,
      challenging: grade.challenging,
      overall: grade.overall,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const changeEssay = async (token, applicant, score1, score2) => {
  if (score1 < 0 || score2 < 0) {
    throw "Scores cannot be negative";
  }

  const res = await instance.patch(
    `/delegate/${applicant.id}/`,
    {
      essay_grade_1: score1,
      essay_grade_2: score2,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export default instance;

export { login, getApplicants, changeGrade, changeEssay };
