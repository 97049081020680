import React from "react";
import { useNavigate } from "react-router-dom";

import green_dot from "../images/green_dot.png";
import yellow_dot from "../images/yellow_dot.png";
import arrow from "../images/arrow.png";

export default function ListItem({ applicant, phase }) {
  let navigate = useNavigate();

  const arrowOnClick = async (id) => {
    navigate(`/grading/${id}`, {
      state: {
        applicant: applicant,
        phase: Number(localStorage.getItem("phase")),
      },
    });
  };
  const gradeStatus =
    phase === 0
      ? applicant.essay_grade_1 !== null || applicant.essay_grade_2 !== null
      : applicant.overall !== null &&
        applicant.academic !== null &&
        applicant.behavior !== null &&
        applicant.challenging !== null &&
        applicant.self_introduction !== null;

  return (
    <tr className="pl-4 flex mx-[60px] h-[55px] text-sm items-center border-b flex-nowrap">
      <Attr value={applicant.gis_code} />
      <Attr value={applicant.name} />
      <Attr value={applicant.nationality} />
      <Attr value={applicant.education_level} />
      <td className="flex w-[100px] h-[20px] mr-12">
        {gradeStatus ? (
          <>
            <img className="w-5 h-5 mr-1" src={green_dot} />
            <p className="w-[80px] bg-white h-[20px]">Graded</p>
          </>
        ) : (
          <>
            <img className="w-5 h-5 mr-1" src={yellow_dot} />
            <p className="w-[80px] bg-white h-[20px]">Ungraded</p>
          </>
        )}
      </td>
      <Attr value={applicant.first_choice} />
      <Attr value={applicant.second_choice} />
      <Attr value={applicant.third_choice} />
      <Attr value={applicant.fourth_choice} />
      {phase === 0 ? (
        <>
          <Attr value={applicant.essay_grade_1 ?? "Ungraded"} />
          <Attr value={applicant.essay_grade_2 ?? "Ungraded"} />
        </>
      ) : (
        <Attr
          value={
            gradeStatus
              ? Number(applicant.overall) +
                Number(applicant.academic) +
                Number(applicant.challenging) +
                Number(applicant.behavior) +
                Number(applicant.self_introduction)
              : "Ungraded"
          }
        />
      )}
      <td className="w-[100px] whitespace-nowrap bg-white h-[20px] mr-12 overflow-hidden">
        <img
          className="w-7 h-7 mr-16 ml-auto cursor-pointer"
          src={arrow}
          onClick={() => arrowOnClick(applicant.id)}
        />
      </td>
    </tr>
  );
}

function Attr({ value }) {
  return (
    <td
      title={value}
      className="w-[100px] whitespace-nowrap bg-white h-[20px] mr-12 overflow-hidden"
    >
      {value}
    </td>
  );
}
